import React from 'react'
import Error500 from './Error500'
import Notification from 'services/api/notification';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        Notification.sendErrorReport(`Error: ${error.message} ${error.stack}`, errorInfo);
        // console.log(`Error: ${error.message} ${error.stack}`, errorInfo)
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Error500 ></Error500>;
        }
        return this.props.children;
    }
}
