import axios from '../config';

const Notification = {
  getUserActivity: ({ fromDate, toDate, type }) => {
    return axios.post('/user-activity/get', { from_date: fromDate, to_date: toDate, type }).then(({ data }) => {
      if (data?.status === 'success') {
        return data?.data || [];
      }
      return [];
    });
  },
  sendErrorReport: (error, info ) => {
    return axios.post('/user-activity/set', { error, info }).then(({ data }) => {
      if (data?.status === 'success') {
        return data?.data || [];
      }
      return [];
    });
  },
  getFrequency: () => {
    return axios.get('/frequency/get_nr_grid_frequency').then(({ data }) => {
      if (data?.status === 'success') {
        return data?.grid_freq?.grid_va || '-';
      }
      return '-';
    });
  },
  downloadFreq: ({ start_date, end_date }) => {
    return axios
      .post(
        '/frequency/nr_grid_data_historical',
        { start_date, end_date },
        {
          responseType: 'blob',
        },
      )
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = 'Frequency.xlsx';
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(res.data, filename);
        } else {
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
  },
};

export default Notification;
